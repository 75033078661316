<template>
  <v-container>
    <v-row >
      <v-col cols="2">
        <h2>会社一覧</h2>
      </v-col>
      <v-col cols="5">
        <v-radio-group 
          v-model="listType"
          dense
          row
          :disabled="loading"
          @change="loadList()"
        >
          <v-radio
            label="会員(本社)"
            :value="0"
            checked
          ></v-radio>
          <v-radio
            label="会員(本社以外)"
            :value="10"
            checked
          ></v-radio>
          <v-radio
            label="非会員"
            :value="1"
          ></v-radio>
          <v-radio
            label="閉鎖廃止"
            :value="2"
          ></v-radio>
          <v-radio
            label="全て"
            :value="99"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="2">
      </v-col>

      <v-col cols="2">
        <v-text-field label="検索" 
              dense v-model="searchString" 
              :disabled="loading"
              :loading="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn class="btn-font primary" v-on:click="show('', 'new')" :disabled="loading" >
          <v-icon left>mdi-plus-circle</v-icon>
          新規会社登録
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchString"
      ref="ref"
      :loading="loading"
      :dense=true
      :items-per-page="10"
      :mobile-breakpoint="0"
      :disable-pagination='true'
      :hide-default-footer='true'
      :fixed-header='true'
      height="calc(100vh - 220px)"
      multi-sort
      locale="ja-jp"
      loading-text="読込中"
      no-data-text="データがありません。"
      class="mt-5"
    >

      <template v-slot:item="{ item }">
        <tr :key="item.company_id" @dblclick="show(item.company_id, 'edit')">
          <td>
            {{item.company_id}}
          </td>
          <td>
            {{item.company_name}}
          </td>
          <td>
            {{item.company_kana}}
          </td>
          <td class="right">
            {{Number(item.capital).toLocaleString()}}
          </td>

          <td class="center px-0" >
            <v-btn small class="mx-1 btn-font primary" v-on:click='show(item.company_id, "edit")'
                :height="20" :width="50" :disabled="loading">
              <v-icon :small="true">mdi-border-color</v-icon>
              編集
            </v-btn>
            <!-- 
            <v-btn small class="mx-1 ml-5 btn-font secondary" v-on:click='show(item.company_id, "delete")'
                :height="20" :width="50" :disabled="loading">
              <v-icon :small="true">mdi-delete</v-icon>
              削除
            </v-btn>
            -->
          </td>
          <td></td>   <!-- 右に余白を開ける -->
        </tr>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2 pl-5">
          <v-col cols="2">
            会社: {{filterItems.length}}
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col cols="8">
          </v-col>
        </v-row>
      </template>

    </v-data-table>

    <!-- modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="40%" id="vm--modal">
      <div >
        <!-- <div class="modal-header mt-5">
          <h2>会社編集</h2>
        </div> -->
        <div class="modal-body">
          <McompanyEdit v-on:closeEvt="hide" :mode="mode" :companyId="companyId" />
        </div>
      </div>
    </modal>

  </v-container>

</template>

<script>
import Mcompany from '../model/m_company';
import McompanyEdit from './McompanyEdit';

import Message from '../Lib/Message';
import Debug from '../Lib/Debug';

export default {
  components: {
    McompanyEdit,
  },

  data: () => ({
    loading: false,

    headers: [
      { text: '登録番号', align: 'left', sortable: true, value: 'company_id', width: 120 },
      { text: '会社名', align: 'left', sortable: true, value: 'company_name', width: 400 },
      { text: '会社名カナ', align: 'left', sortable: true, value: 'company_kana', width: 400 },
      { text: '資本金', align: 'left', sortable: true, value: 'capital', width: 120 },
      { text: '', align: 'center', sortable: false, value: 'action', width: 300 },
      { text: '', align: 'center', sortable: false }    // 右に余白を開ける
    ],

    items: [],

    //
    searchString: "",
    listType: 0,

    // edit用
    mode: '',
    companyId: '',
  }),

  methods: {
    async loadList() {
      Debug.log('function[loadList]');

      this.loading = true
      try {
        const res = await Mcompany.getDataList(this.listType);

        // 会社データ
        if (res && res.data) {
          Debug.log(res.data);

          this.items = res.data;
        }

      } catch (error) {
        Message.err(error, '情報を取得できませんでした。[ユーザデータ]');
      }
      this.loading = false
    },

    // モーダル表示用
    show (editid, mode) {
      Debug.log('function[show]');
      this.mode = mode;
      this.companyId = editid; 

      Debug.log('edit mode : ' + mode);
      Debug.log('disp company Id : ' + editid);

      this.$modal.show('modal-dialog');
    },
    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');

      // TABLEを読み直す
      this.loadList();
    },

    updateEvt () {
      Debug.log('function[updateEvt]');

      this.hide();
    },

  },

  computed: {
    filterItems() {
      if (this.items.length > 0) {
        return this.$refs.ref.$children[0].filteredItems;
      } else {
        return [];
      }
    },
  },

  created: function() {
    this.loadList()
  },

  mounted : function() {

  }

}

</script>


<style scoped>

.v-data-table table {
  width: initial !important;
}

.v-data-table table th {
  font-size: 12pt;
}

.v-data-table table td {
  font-size: 11pt;
}

/* modal dialog */
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

.vm--modal {
  min-width: 400px;
  max-width: 600px;
}

.btn-font {
  color: white;
}

.v-text-field {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

th {
  white-space: pre-line;
}

.v-input--radio-group {
  margin: 0;
}

</style>
